import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import ProdottiService from 'services/ProdottiService';
import {PRODOTTI} from '../../configs/AppConfig';

export const initialState = PRODOTTI;

export const loadProdotti = createAsyncThunk('carica-prodotti', async (data, {rejectWithValue}) => {
    console.log("[loadProdotti]");

    try {
        //console.log("ale-debug loadProvincie")
        const response = await ProdottiService.getProdotti();
        //console.log("ale-debug loadProvincie response",response)
        //localStorage.setItem("PRODOTTI", JSON.stringify(response.data))
        console.table(response.data)

        return response.data;
    } catch (err) {
        console.error(err)
        return rejectWithValue('ERRORE')
    }
})

export const prodotti = createSlice({
    name: 'prodotti', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadProdotti.pending, (state) => {
                state.loadingProdotti = true
                state.loadedProdotti = false
                state.errorProdotti = false
            })
            .addCase(loadProdotti.fulfilled, (state, action) => {
                state.loadingProdotti = false
                state.prodotti = action.payload
                state.errorProdotti = false
                state.loadedProdotti = true
            })
            .addCase(loadProdotti.rejected, (state, action) => {
                state.loadingProdotti = false
                state.prodotti = []
                state.loadedProdotti = true
                state.errorProdotti = true
            })
    }
})


export default prodotti.reducer;