import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {DASHBOARD_GENERALE,} from "../../configs/AppConfig";
import {errorMessage} from "../../utils/messages";
import DashboardService from "../../services/DashboardService";
import moment from 'moment';

export const initialState = DASHBOARD_GENERALE;

export const loadDashboardSlices = createAsyncThunk('carica-dashboard-generale', async (data, {rejectWithValue}) => {
    console.log("[loadDashboardSlices]");
    const {puntiVendita} = data;
    try {
        //CALCOLO DATA INIZIO E DATAFINE
        const type="months"
        const numerType=6;
        let currentDate = moment(moment().format('YYYY-MM-DD'));
        const dataInizio = currentDate.subtract(numerType, type).startOf('month').format('YYYY-MM-DD');
        currentDate = moment(moment().format('YYYY-MM-DD'));
        const dataFine = currentDate.subtract(numerType, type).endOf('month').format('YYYY-MM-DD');

        console.log(puntiVendita)
        const metriche = await DashboardService.getMetrics(puntiVendita);

        const visiteInScadenza = await DashboardService.getVisiteInScadenza(dataInizio,dataFine,puntiVendita);

        let response ={
            "metriche":metriche,
            "visite":visiteInScadenza,

        }
        console.table(response)
        return response;

    } catch (err) {
        console.error(err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare le statistiche. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const dashboardGenerale = createSlice({
    name: 'dashboardGenerale', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadDashboardSlices.pending, (state) => {

                state.loadingDashboardGenerale = true
                state.loadedDashboardGenerale = false
                state.errorDashboardGenerale = false
            })
            .addCase(loadDashboardSlices.fulfilled, (state, action) => {
                //console.log("ale-debug fulfilled",action)
                state.loadingDashboardGenerale = false
                state.loadedDashboardGenerale = true
                state.statisticheDashboardGenerale = action.payload
                state.errorDashboardGenerale = false

            })
            .addCase(loadDashboardSlices.rejected, (state, action) => {
                //console.log("ale-debug rejected",action)
                state.loadingDashboardGenerale = false
                state.loadedDashboardGenerale = true
                state.statisticheDashboardGenerale = {}
                state.errorDashboardGenerale = true
            })
    }
})


export default dashboardGenerale.reducer;