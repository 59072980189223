import {createAsyncThunk, createSlice,createAction } from '@reduxjs/toolkit'
import {EVENTI} from 'configs/AppConfig'
import {errorMessage} from "../../utils/messages";
import CalendarService from "../../services/CalendarService";
import moment from "moment";
import dayjs from "dayjs";
import "moment/locale/it";
export const initialState = {
    //eventi
    events:  [],
    //stati dicaricmento degli eventi iniziali o quando supero il range
    loadingEvents: false,
    loadedEvents:  false,
    errorEvents: false,
    //RIFERIMENTI CHE MI MANTENGONO AGGIORNATO GLI l'INIZIO E LA FINE DI DOVE HO GIA SCARICATO
    indexStart: moment(moment(moment()).startOf('month')).subtract(0, 'months').startOf('month').subtract(1,"weeks"),
    indexEnd: moment(moment()).add(0, 'months').endOf('month').add(1,"weeks"),
    //stati di carimento quando modifico aggiungo evento
    loadingAddEditEvents: false,
    loadedAddEditEvents:  false,
    errorAddEditEvents: false,
}

export const loadEvents = createAsyncThunk('carica-eventi', async (input ,{rejectWithValue,getState}) => {


    try {


        let eventiPresenti = getState().events.events
        const puntivendita=input.puntivendita
        const start = input.start.format('YYYY-MM-DD HH:mm')
        const end = input.end.format('YYYY-MM-DD HH:mm')

        //OTTENERE GLI UTENTI
        const response = await CalendarService.getEvents(puntivendita,start,end);
        const data=response.data

        let newEvents =  data.map(event => (setEvent(event)))
        return [...eventiPresenti,...newEvents]

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare gli eventi. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const addNewEvent = createAsyncThunk('aggiungi-eventi', async (body, { rejectWithValue, getState }) => {
    try {


        const currentState = getState().events.events;


        if((typeof body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(body.data.start.format())) || getState().events.indexStart.isAfter(moment(body.data.end.format())))) {
            await CalendarService.addEvent(body);
            return [...currentState]
        }
        else {
            const response = await CalendarService.addEvent(body);
            return [...currentState, ...[setEvent(response.data)]];
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile aggiungere l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const editEvent = createAsyncThunk('modifica-eventi', async (input, { rejectWithValue, getState }) => {
    try {

        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))

        if((typeof input.body.data.start.format === 'function') && (getState().events.indexEnd.isBefore(moment(input.body.data.start.format())) || getState().events.indexStart.isAfter(moment(input.body.data.end.format())))){
            await CalendarService.editEvent(input.id,input.body);
            const indexobj = currentState.findIndex((element) => element.id === input.id);
            currentState.splice(indexobj,1)
            return currentState
        }
        else {
            const response = await CalendarService.editEvent(input.id,input.body);
            const indexobj = currentState.findIndex((element) => element.id === input.id);
            currentState[indexobj] = setEvent(response.data);
            return currentState
        }
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const deleteEvent = createAsyncThunk('elimina-evento', async (id, { rejectWithValue, getState }) => {
    try {

        let currentState = getState().events.events.map((item) => ({
            ...item,
        }))

        await CalendarService.deleteEvent(id);
        const indexobj = currentState.findIndex((element) => element.id === id);
        currentState.splice(indexobj,1)
        return currentState

    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Non è stato possibile modificare l'evento.
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
});

export const setIndexStart = createAction('setIndexStart');
export const setIndexEnd = createAction('setIndexEnd');

//funzioni locali
const setEvent = (event) => {
    return {
        id: event.id,
        start: new Date(event.attributes.start),
        end: new Date(event.attributes.end),
        title: event.attributes.nome_appuntamento,
        note:event.attributes.note,
        stato:event.attributes.stato,
        tipo:event.attributes.tipo,
        pazientiLabel:event.attributes.pazientiLabel,
        store:event.attributes.storeLabel,
        color:"#"+event.attributes.colore,
        operatore:event.attributes.operatoreLabel,
        //color:"#"+event.attributes.operatore.data.attributes.color,
        //operatore:event.attributes.operatore.data.id,
        //store:event.attributes.store.data.id,
    }
}
export const CalendarSlice = createSlice({
    name: 'events', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadEvents.pending, (state) => {
                state.loadingEvents = true
                state.loadedEvents = false
                state.errorEvents = false
            })
            .addCase(loadEvents.fulfilled, (state, action) => {
                state.loadingEvents = false
                state.events = action.payload
                state.loadedEvents = true
                state.errorEvents = false
            })
            .addCase(loadEvents.rejected, (state, action) => {
                state.loadingEvents = false
                state.events = []
                state.loadedEvents = true
                state.errorEvents = true
            })
            .addCase(addNewEvent.pending, (state) => {
                state.loadingAddEditEvents= true
                state.loadedAddEditEvents= false
                state.errorAddEditEvents= false
            })
            .addCase(addNewEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= false
            })
            .addCase(addNewEvent.rejected, (state, action) => {
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= true
            })
            .addCase(editEvent.pending, (state) => {
                state.loadingAddEditEvents= true
                state.loadedAddEditEvents= false
                state.errorAddEditEvents= false
            })
            .addCase(editEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= false
            })
            .addCase(editEvent.rejected, (state, action) => {
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= true
            })
            .addCase(deleteEvent.pending, (state) => {
                state.loadingAddEditEvents= true
                state.loadedAddEditEvents= false
                state.errorAddEditEvents= false
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.events = action.payload
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= false
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.loadingAddEditEvents= false
                state.loadedAddEditEvents= true
                state.errorAddEditEvents= true
            })
            .addCase(setIndexStart, (state, action) => {
                state.indexStart = action.payload;
            })
            .addCase(setIndexEnd, (state, action) => {
                state.indexEnd = action.payload;
            });


    }
})



export default CalendarSlice.reducer;