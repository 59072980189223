import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {PAZIENTI} from 'configs/AppConfig'
import StrapiService from "../../services/StrapiService";
import moment from "moment";
import {errorMessage} from "../../utils/messages";
import {PAZIENTI_ERROR, PAZIENTI_LOADED, PAZIENTI_VALUE} from "../../constants/AuthConstant";

export const initialState = PAZIENTI

export const loadPazienti = createAsyncThunk('carica-pazienti', async (data, {rejectWithValue}) => {
    console.log("[loadPazienti]");

    try {
        let queryString = ""
        for (let i = 0; i < data.length; i++) {
            queryString += "&filters[binding_roles][id]=" + data[i]
        }
        //api/stores?&pagination[limit]=10000populate=*&filters[binding_roles][id]=5
        const response = await StrapiService.get('api/stores?&pagination[limit]=-1&populate=*&' + queryString);

        console.log("ale-debug1  response", response)
        let pazienti = []
        for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < response.data[i].attributes.patients.data.length; j++) {
                let paziente = response.data[i].attributes.patients.data[j]
                pazienti.push({
                    id: paziente.id,
                    nome: paziente.attributes.nome,
                    cognome: paziente.attributes.cognome,
                    data_di_nascita: paziente.attributes.data_di_nascita == null ? "N.D" : moment(paziente.attributes.data_di_nascita).format("DD-MM-YYYY"),
                    citta: paziente.attributes.citta == null ? "N.D" : paziente.attributes.citta,
                    citta_di_residenza: paziente.attributes.citta_di_residenza == null ? "N.D" : paziente.attributes.citta_di_residenza,
                    tipo_provenienza: paziente.attributes.tipo_provenienza,
                    nome_provenienza: paziente.attributes.nome_provenienza,
                    stato_paziente: paziente.attributes.stato_paziente,
                    tipo_paziente: paziente.attributes.tipo_paziente,
                    provenienza: paziente.attributes.tipo_provenienza + " " + paziente.attributes.nome_provenienza,
                    telefono:paziente.attributes.telefono_fisso
                })
            }
        }

        localStorage.setItem(PAZIENTI_VALUE, JSON.stringify(pazienti))

        console.table(pazienti)
        return pazienti
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const pazientiSlice = createSlice({
    name: 'pazienti', initialState, reducers: {
        pazientiLoaded: (state, action) => {
            //console.log("state", state)
            state.loading = false
            state.pazienti = action.payload;
        },
        showLoading: (state) => {
            state.loading = true
        },
        updatePaziente: (state, action) => {
            const {id, updatedFields} = action.payload;
            state.pazienti = state.pazienti.map((paziente) => {
                if (paziente.id == id) {
                    const campiDaAggiornare = {};
                    for (const campo in updatedFields) {
                        if (campo in paziente) {
                            // questo if è importante, serve per modificare solo le chiavi del json che fanno parte della listaPazienti del redux!
                            if (updatedFields[campo] != null) {
                                if (campo !== "data_di_nascita") {
                                    campiDaAggiornare[campo] = updatedFields[campo];
                                } else {
                                    campiDaAggiornare[campo] = moment(updatedFields[campo]).format("DD-MM-YYYY");
                                }
                            } else {
                                campiDaAggiornare[campo] = "N.D";
                            }
                        }
                    }

                    return {
                        ...paziente,
                        ...campiDaAggiornare, // Combina i campi esistenti con i campi aggiornati
                    };
                }
                return paziente;
            });
        },
    }, extraReducers: (builder) => {
        builder
            .addCase(loadPazienti.pending, (state) => {
                state.loading = true
                state.loadedPazienti = false
                state.errorPazienti = false
            })
            .addCase(loadPazienti.fulfilled, (state, action) => {
                state.loading = false
                state.pazienti = action.payload
                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, false)
                state.loadedPazienti = true
                state.errorPazienti = false
            })
            .addCase(loadPazienti.rejected, (state, action) => {
                state.loading = false
                state.pazienti = []
                localStorage.setItem(PAZIENTI_LOADED, true)
                localStorage.setItem(PAZIENTI_ERROR, true)
                localStorage.removeItem(PAZIENTI_VALUE)
                state.loadedPazienti = true
                state.errorPazienti = false
            })
    }
})

export const {
    updatePaziente,
} = pazientiSlice.actions

export default pazientiSlice.reducer;