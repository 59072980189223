import React, {useEffect} from 'react';
import {Routes as RouterRoutes, Route, Navigate} from 'react-router-dom';
import {AUTHENTICATED_ENTRY} from 'configs/AppConfig';
import {protectedRoutes, protectedRoutesPaziente, publicRoutes} from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import {useSelector} from "react-redux";

const Routes = () => {

    const {auth} = useSelector(state => state)

    useEffect(() => {
        console.log("auth", auth)

    }, [])

    return (
        <RouterRoutes>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Navigate replace
                                                   to={auth?.ruolo?.type == "paziente" ? "/anagrafica" : "/dashboards/default"}/>}/>
                {auth?.ruolo?.type == "paziente" ?
                    protectedRoutesPaziente.map((route, index) => {
                        return (
                            <Route
                                key={route.key + index}
                                path={route.path}
                                element={
                                    <AppRoute
                                        routeKey={route.key}
                                        component={route.component}
                                        {...route.meta}
                                    />
                                }
                            />
                        )
                    })
                    :
                    protectedRoutes.map((route, index) => {
                        return (
                            <Route
                                key={route.key + index}
                                path={route.path}
                                element={
                                    <AppRoute
                                        routeKey={route.key}
                                        component={route.component}
                                        {...route.meta}
                                    />
                                }
                            />
                        )
                    })

                }
                <Route path="*" element={<Navigate to="/not-found" replace/>}/>
            </Route>
            <Route path="/" element={<PublicRoute/>}>
                {publicRoutes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <AppRoute
                                    routeKey={route.key}
                                    component={route.component}
                                    {...route.meta}
                                />
                            }
                        />
                    )
                })}
            </Route>
        </RouterRoutes>
    );
}

export default Routes