export const AUTH_TOKEN = 'auth_token'
export const INFO_USER = 'info_user'
export const ROLE = 'role'
export const BINDING_ROLES = 'binding_roles'

//redux: PAZIENTI
export const PAZIENTI_VALUE = 'pazienti'
export const PAZIENTI_LOADED = 'pazienti_loaded'
export const PAZIENTI_ERROR = 'pazienti_error'

//redux: PUNTI VENDITA
export const PUNTI_VENDITA_VALUE = 'punti_vendita'
export const PUNTI_VENDITA_LOADED = 'punti_vendita_loaded'
export const PUNTI_VENDITA_ERROR = 'punti_vendita_error'

//redux: PROVENIENZE
export const PROVENIENZE_VALUE = 'provenienze'
export const PROVENIENZE_LOADED = 'provenienze_loaded'
export const PROVENIENZE_ERROR = 'provenienze_error'

//redux: PAZIENTI STATI E TIPI
export const PAZIENTI_STATI_TIPI_VALUE = 'pazienti_stati_tipi'
export const PAZIENTI_STATI_TIPI_LOADED = 'pazienti_stati_tipi_loaded'
export const PAZIENTI_STATI_TIPI_ERROR = 'pazienti_stati_tipi_error'