import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AUTH_TOKEN, BINDING_ROLES, INFO_USER, ROLE} from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import {loadPuntiVendita} from "./puntiVenditaSlice";
import store from '../index'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    ruolo: JSON.parse(localStorage.getItem(ROLE)) || null,
    user: JSON.parse(localStorage.getItem(INFO_USER)) || null,
    binding_roles: JSON.parse(localStorage.getItem(BINDING_ROLES)) || null

}

export const signIn = createAsyncThunk('auth/login', async (data, {rejectWithValue}) => {
    console.log("[signIn]");
    console.log("ale-debug signIn")
    const {email, password} = data
    try {
        let data = {
            "identifier": email,
            "password": password
        }

        //RECUPERO JWT + INFO USER
        const response = await AuthService.login(data)
        console.log("ale-debug response", response)
        const {jwt, user} = response
        localStorage.setItem(AUTH_TOKEN, jwt);
        localStorage.setItem(INFO_USER, JSON.stringify(user));

        //RECUPERO ROLE
        const response_role = await AuthService.getRole();
        console.log("ale-debug response_role", response_role)
        const ruolo = response_role
        localStorage.setItem(ROLE, JSON.stringify(ruolo.role));
        localStorage.setItem(BINDING_ROLES, JSON.stringify(ruolo.binding_roles));

        //RECUPERO I PUNTI VENDITA A ME ASSOCIATI
        // RECUPERO I PUNTI VENDITA A ME ASSOCIATI
        store.dispatch(loadPuntiVendita(response_role));

        let auth = {}
        auth.jwt = jwt;
        auth.ruolo = ruolo.role;
        auth.user = user;
        auth.binding_roles = ruolo.binding_roles;
        console.table(auth)
        return {jwt: jwt, role: ruolo.role, user: user, binding_roles: ruolo.binding_roles};
    } catch (err) {
        console.log("err", err)
        //return rejectWithValue(err?.message || 'Error')
        localStorage.clear();

        return rejectWithValue('Autenticazione fallita')
    }
})

export const signUp = createAsyncThunk('auth/register', async (data, {rejectWithValue}) => {
    const {email, password} = data
    try {
        const response = await AuthService.register({email, password})
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signOut = createAsyncThunk('auth/logout', async () => {
    //const response = await FirebaseService.signOutRequest()
    localStorage.clear();

    /*
    localStorage.removeItem(auth_token);
    localStorage.removeItem(INFO_USER);
    localStorage.removeItem(ROLE);

     */
    // return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, {rejectWithValue}) => {
    try {
        const response = await AuthService.loginInOAuth()
        const token = response.data.token;
        localStorage.setItem(AUTH_TOKEN, token);
        return token;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false
            state.redirect = '/'
            state.token = action.payload
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideAuthMessage: (state) => {
            state.message = ''
            state.showMessage = false
        },
        signOutSuccess: (state) => {
            state.loading = false
            state.token = null
            state.redirect = '/'
        },
        showLoading: (state) => {
            state.loading = true
        },
        signInSuccess: (state, action) => {
            state.loading = false
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signIn.pending, (state) => {
                state.loading = true
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload.jwt
                state.ruolo = action.payload.role
                state.user = action.payload.user
                state.binding_roles = action.payload.binding_roles
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
                localStorage.clear();

            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false
                state.token = null
                state.redirect = '/'
                localStorage.clear();

            })
            .addCase(signUp.pending, (state) => {
                state.loading = true
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signUp.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithGoogle.pending, (state) => {
                state.loading = true
            })
            .addCase(signInWithGoogle.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithGoogle.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
            .addCase(signInWithFacebook.pending, (state) => {
                state.loading = true
            })
            .addCase(signInWithFacebook.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.token = action.payload
            })
            .addCase(signInWithFacebook.rejected, (state, action) => {
                state.message = action.payload
                state.showMessage = true
                state.loading = false
            })
    },
})

export const {
    authenticated,
    showAuthMessage,
    hideAuthMessage,
    signOutSuccess,
    showLoading,
    signInSuccess
} = authSlice.actions

export default authSlice.reducer