import {DIR_LTR, NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'
import {
    PAZIENTI_ERROR,
    PAZIENTI_LOADED,
    PAZIENTI_STATI_TIPI_ERROR,
    PAZIENTI_STATI_TIPI_LOADED,
    PAZIENTI_STATI_TIPI_VALUE,
    PAZIENTI_VALUE,
    PROVENIENZE_ERROR,
    PROVENIENZE_LOADED,
    PROVENIENZE_VALUE,
    PUNTI_VENDITA_LOADED,
    PUNTI_VENDITA_VALUE
} from "../constants/AuthConstant";

export const APP_NAME = 'Nutri-Assistant';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/`;
export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'en',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#3e82f7',
    headerNavColor: '',
    mobileNav: false,
    currentTheme: 'light',
    direction: DIR_LTR,
    blankLayout: false
};


/*** STATI REDUX ***/
export const PAZIENTI = {
    loadingPazienti: false,
    loadedPazienti: JSON.parse(localStorage.getItem(PAZIENTI_LOADED)) || false,
    pazienti: JSON.parse(localStorage.getItem(PAZIENTI_VALUE)) || [],
    errorPazienti: JSON.parse(localStorage.getItem(PAZIENTI_ERROR)) || false
};
export const UTENTI = {
    loadingUtenti: false,
    loadedUtenti:  false,
    utenti:  [],
    errorUtenti: false
};

export const EVENTI = {
    loadingEvents: false,
    loadedEvents:  false,
    events:  [],
    errorEvents: false,
};
export const SCHEDA_PAZIENTE = {
    loadingSchedaPaziente: false,
    loadedSchedaPaziente: false,
    schedaPaziente: {},
    errorSchedaPaziente: false,
    defaultTabKey: 1
};

export const PUNTI_VENDITA = {
    loadingPuntiVendita: false,
    loadedPuntiVendita: localStorage.getItem(PUNTI_VENDITA_LOADED) || false,
    puntiVendita: JSON.parse(localStorage.getItem(PUNTI_VENDITA_VALUE)) || [],
    errorPuntiVendita: JSON.parse(localStorage.getItem(PAZIENTI_ERROR)) || false
};

export const PROVENIENZE = {
    loadingProvenienze: false,
    loadedProvenienze: localStorage.getItem(PROVENIENZE_LOADED) || false,
    provenienze: JSON.parse(localStorage.getItem(PROVENIENZE_VALUE)) || [],
    errorProvenienze: JSON.parse(localStorage.getItem(PROVENIENZE_ERROR)) || false
};

export const PAZIENTI_STATI_TIPI = {
    loadingPazientiStatiTipi: false,
    loadedPazientiStatiTipi: Boolean(localStorage.getItem(PAZIENTI_STATI_TIPI_LOADED)) || false,
    pazientiStatiTipi: JSON.parse(localStorage.getItem(PAZIENTI_STATI_TIPI_VALUE)) || [],
    errorPazientiStatiTipi: Boolean(JSON.parse(localStorage.getItem(PAZIENTI_STATI_TIPI_ERROR))) || false
};

export const ASSEGNAZIONE_INTERNA = [
    {
        title: "audioprotesista",
        type: "select",
        options: [""],
        rules: [
            {
                required: true,
                message: 'Inserire un nome obbligatorio',
            }
        ]
    }
]



export const DATI_ASL = [
    {
        title: "data_di_autorizzazione",
        type: "date"
    },
    {
        title: "data_di_collaudo",
        type: "date"
    },
    {
        title: "scadenza_garanzia",
        type: "date"
    },
]

export const VISITE = [
    {
        title: "data_visita",
        type: "date",
        rules: [
            {
                required: true,
                message: 'Inserisci una data obbligatoria',
            }
        ]
    }
]


export const STATI_TIPI = [
    {
        title: "stato_paziente",
        type: "select",
        options: [""],
        disabled: true
    },
    {
        title: "tipo_paziente",
        type: "select",
        options: [""]
    }
]

export const DASHBOARD_GENERALE = {
    loadingDashboardGenerale: false,
    statisticheDashboardGenerale: {},
    loadedDashboardGenerale: false,
    errorDashboardGenerale: false
};

export const PRODOTTI = {
    loadingProdotti: false,
    prodotti: [],
    loadedProdotti: false,
    errorProdotti: false
}

export const TYPE_PAZIENTI = {
    loadingTypesPazienti: false,
    typesPazienti: [],
    loadedTypesPazienti: false,
    errorTypesPazienti: false
};