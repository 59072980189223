import fetch from 'auth/GenericFetchInterceptor'

const ProvenienzeService = {}


ProvenienzeService.getProvenienze = function (queryString) {
    return fetch({
        url: `/api/sources?&pagination[limit]=-1&populate=sources&populate=stores`+queryString,
        method: 'GET'
    })
}

ProvenienzeService.inserisciProvenienza = function (data) {
    return fetch({
        url: `/api/sources`,
        method: 'POST',
        data:data
    })
}
ProvenienzeService.deleteProvenienza = function (id) {
    return fetch({
        url: `/api/sources/${id}`,
        method: 'DELETE',
    })
}




export default ProvenienzeService;