import axios from 'axios';
import {API_BASE_URL} from 'configs/AppConfig';
import {AUTH_TOKEN} from 'constants/AuthConstant';
import {notification} from 'antd';
import {InvalidDate} from "../constants/error-messages/ApiConstant";

const unauthorizedCode = [400, 401, 403, 404, 500, 508]

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 360000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
    if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + jwtToken
    }
    if (config.url.includes("downloadfile")) {
        config['responseType'] = "blob";
    } else if (config.url.includes("upload")) {
        config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Errore'
    })
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {

    return response.data
}, (error) => {
    let notificationParam = {
        message: 'Errore Generico'
    }

    let description = ''

    switch (error.response.data.error.message) {
        case 'Invalid date':
            description = InvalidDate
            break;

        default:
            description = error.response.statusText
    }
    // PREPARAZIONE MESSAGGIO DI NOTIFICA IN ALTO A DESTRA
    if (unauthorizedCode.includes(error.response?.status)) {
        notificationParam.message = 'Errore'
        notificationParam.description = description
    }
    notification.error(notificationParam)
    return Promise.reject(error);
});

export default service