const dev = {
    API_ENDPOINT_URL: 'https://server.maffle.it:8443/nutri-assistant/sviluppo'
};
const coll = {
    API_ENDPOINT_URL: 'https://server.maffle.it:8443/nutri-assistant/collaudo'
};
const prod = {
    API_ENDPOINT_URL: 'https://nutriassistant.it'
};


const getEnv = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'sviluppo':
            return dev
        case 'collaudo':
            return coll
        case 'produzione':
            return prod
        default:
            return {
                API_ENDPOINT_URL: 'http://localhost:1338'
            };
    }
}

export const env = getEnv()
