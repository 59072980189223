import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig'

const ProtectedRoute = () => {
	
	const { token } = useSelector(state => state.auth)
	const expirationDate = token!=null ? new Date(JSON.parse(atob(token?.split('.')[1])).exp * 1000): null;
	const currentDate = new Date();

	const location = useLocation()
	if (!token || currentDate>expirationDate) {
		localStorage.removeItem("auth_token");
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return <Outlet />
}

export default ProtectedRoute