import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {UTENTI} from 'configs/AppConfig'
import {errorMessage} from "../../utils/messages";
import CalendarService from "../../services/CalendarService";

export const initialState = UTENTI

export const loadUtenti = createAsyncThunk('carica-utenti', async (bindings_role, {rejectWithValue}) => {


    try {
        console.log("load utenti",bindings_role)

        //BINDIGS ROLE ID
        let binding_output  = bindings_role.map(role => role.id)

        //COSTRUIRE I NOMI E GLI ID DEI PUNTI VENDITA ABILITATI ATTRAVERSO L'OGGETTO BINDIGS ROLE
        let puntiVenditaAbilitati=[]
        let queryString = ""
        for (let i = 0; i < bindings_role.length; i++) {
            queryString += `&filters[binding_roles][store][name]=${bindings_role[i].store.name}`
            puntiVenditaAbilitati.push({name:bindings_role[i].store.name,id:bindings_role[i].store.id})
        }

        //OTTENERE GLI UTENTI CHE SONO ABILITATI SUGLI STESSI PUNTI VENDITA (ATTRAVERSO I NOMI DEGLI STORE)
        const response = await CalendarService.getUserPuntiVendita(queryString);
        const user = response.map(item => ({
            nome: item.username,
            id: item.id,
            username: item.username,
            color: item.color,
            store: item.binding_roles.map(role => role.store.name),
            store_id: item.binding_roles.map(role => role.store.id)
        }));


        //STATO OUTPUT
        let userAbilitati={
            puntiVendita:puntiVenditaAbilitati,
            userTotali:user,
            role:binding_output
        }

        return userAbilitati
    } catch (err) {
        console.error("err", err)
        let message = (
            <div>
                Qualcosa è andato storto nel recuperare i pazienti. Riprovare!
                <br/>
                Se il problema persiste, contattare l' assistenza.
            </div>
        );

        errorMessage(message)
        return rejectWithValue('ERRORE')
    }
})

export const utentiSlice = createSlice({
    name: 'utenti', initialState, reducers: {}, extraReducers: (builder) => {
        builder
            .addCase(loadUtenti.pending, (state) => {
                state.loadingUtenti = true
                state.loadedUtenti = false
                state.errorUtenti = false

            })
            .addCase(loadUtenti.fulfilled, (state, action) => {
                state.loadingUtenti = false
                state.utenti = action.payload
                state.loadedUtenti = true
                state.errorUtenti = false
            })
            .addCase(loadUtenti.rejected, (state, action) => {
                state.loadingUtenti = false
                state.utenti = []
                state.loadedUtenti = true
                state.errorUtenti = true
            })
    }
})



export default utentiSlice.reducer;