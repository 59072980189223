import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {

    return fetch({
        url: '/api/auth/local?populate=*',
        method: 'post',
        data: data
    })
}
AuthService.getRole = function (data) {
    //SOSTITUITO PER OTTENERE ANCHE I NOMI
    /*return fetch({
        url: '/api/users/me?populate=*',
        method: 'get',
    })*/
    return fetch({
        url: '/api/users/me?pagination[limit]=-1&populate[binding_roles][populate][0]=store&populate=role',
        method: 'get',
    })
}


AuthService.resetPassword = function (data) {

    return fetch({
        url: '/api/auth/reset-password',
        method: 'post',
        data: data
    })
}

AuthService.forgotPassword = function (data) {

    return fetch({
        url: '/api/auth/forgot-password',
        method: 'post',
        data: data
    })
}
AuthService.createUser = function (data) {
    return fetch({
        url: '/api/users',
        method: 'post',
        data: data
    })
}

AuthService.logout = function () {
    return fetch({
        url: '/auth/logout',
        method: 'post'
    })
}


export default AuthService;