import fetch from 'auth/GenericFetchInterceptor'

const DashboardService = {}


DashboardService.getMetrics = function (puntiVendita) {
    return fetch({
        url: `/api/audiomedical/dashboard`,
        method: 'POST',
        data: {puntiVendita: puntiVendita}
    })
}


DashboardService.getVisiteInScadenza = function (dataInizio, dataFine,puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[patient][store]=${store.id}`);
    return fetch({
        url: `/api/visits?pagination[limit]=-1&fields[0]=data_visita&filters[data_visita][$lte]=${dataFine}&filters[data_visita][$gte]=${dataInizio}&populate[patient][fields][0]=nome&populate[patient][fields][1]=cognome${queryStringArray.join('')}`,
        method: 'GET'
    })

}


export default DashboardService;